<template>
  <div class="merlends">
    <!-- 搜索列 -->
    <el-form
      ref="queryForm"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item prop="businessName">
        <el-input
          v-model="queryParams.businessName"
          placeholder="供应商编号/名称模糊查询"
          clearable
        />
      </el-form-item>
      <el-form-item prop="startTime">
        <el-date-picker
          v-model="queryParams.startTime"
          type="daterange"
          range-separator="至"
          start-placeholder="提交开始日期"
          end-placeholder="提交结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item prop="serialNumber">
        <el-input
          v-model="queryParams.serialNumber"
          placeholder="审批编号"
          clearable
        />
      </el-form-item>
      <el-form-item prop="createUserName">
        <el-input
          style="width: 280px"
          v-model="queryParams.createUserName"
          placeholder="提交人、账号查询/处理人、账号查询"
          clearable
        />
      </el-form-item>
      <el-form-item prop="auditType">
        <el-select
          v-model="queryParams.auditType"
          placeholder="提交类型"
          clearable
        >
          <el-option label="供应商入驻" :value="1"></el-option>
          <el-option label="更新企业信息" :value="2"></el-option>
          <el-option label="更新资质" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="auditStatus">
        <el-select
          v-model="queryParams.auditStatus"
          placeholder="审批处理状态"
          clearable
        >
          <el-option label="待审核" :value="0"></el-option>
          <!-- <el-option label="拒绝" :value="9"></el-option> -->
          <el-option label="审核驳回" :value="10"></el-option>
          <el-option label="审核通过" :value="20"></el-option>
          <el-option label="已撤销" :value="30"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查 询</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      v-loading="loading"
      :data="reviewData"
      border
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column
        prop="serialNumber"
        label="审批编号"
        align="center"
        width="220"
      />
      <el-table-column prop="createTime" label="提交时间" align="center" />
      <el-table-column prop="createUserName" label="提交人" align="center">
        <template slot-scope="{ row }"
          >{{ row.createUserName }} / {{ row.createUserAccount }}</template
        >
      </el-table-column>
      <el-table-column prop="" label="编号/供应商名称" align="center">
        <template slot-scope="{ row }"
          >{{ row.businessId }} / {{ row.businessName }}</template
        >
      </el-table-column>
      <el-table-column prop="address" label="企业类型" align="center">
        <template slot-scope="{ row }">
          {{ row.businessType | busType }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="提交类型" align="center">
        <template slot-scope="{ row }">
          {{ row.auditType | audType }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="处理状态"
        align="center"
        width="200px"
      >
        <template slot-scope="{ row }">
          <div>{{ row.auditStatus | audStatus }}</div>
          <div class="yellos" v-if="row.auditStatus == 10" :title="row.remark">
            <div class="hider">(原因：{{ row.remark }})</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="operationUserName" label="处理人" align="center">
        <template slot-scope="{ row }"
          >{{ row.operationUserName }} /
          {{ row.operationUserAccount }}</template
        >
      </el-table-column>
      <el-table-column
        prop="operationUserUpdateTime"
        label="完成操作时间"
        align="center"
      />
      <el-table-column prop="address" label="操作" align="center" width="150">
        <template slot-scope="{ row }">
          <span
            class="btsed"
            @click="examine(row)"
            v-if="row.auditStatus == 0 && allJurisdiction.goodsAudit159"
            >审核</span
          >
          <span
            class="btsed"
            v-if="row.auditStatus != 0 && allJurisdiction.goodsAudit158"
            @click="details(row)"
            >详情</span
          >
          <!-- 3.7.4版本隐藏 -->
          <!-- <span
            class="btsed"
            v-if="allJurisdiction.goodsAudit157"
            @click="record(row)"
            >记录</span
          > -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      loading: false,
      queryParams: {
        page: 1, //页码
        perPage: 10, //每页大小
        businessName: "", //供应商编号/名称模糊查询
        startTime: [], //提交时间
        serialNumber: "", //审核编号
        createUserName: "", //提交人、账号查询/处理人、账号查询
        auditType: "", //提交类型
        auditStatus: "", //审批处理状态
      },
      reviewData: [],
      total: 0,
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...mapState(["goodsAuditPage"]),
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  filters: {
    busType(v) {
      switch (v) {
        case 1:
          return "经营企业（批发）";
        case 3:
          return "经营企业（连锁）";
        case 2:
          return "生产企业";
      }
    },
    audType(v) {
      switch (v) {
        case 1:
          return "供应商入驻";
        case 2:
          return "更新企业信息";
        case 3:
          return "更新资质";
      }
    },
    audStatus(v) {
      switch (v) {
        case 0:
          return "待审核";
        case 9:
          return "拒绝";
        case 10:
          return "审核驳回";
        case 20:
          return "审核通过";
        case 30:
          return "已撤销";
      }
    },
  },
  methods: {
    ...mapActions(["getreviewList"]),
    ...mapMutations(["cgGoodsAuditPage"]),
    ...commonIndex.mapMutations(["changeSeach"]),

    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    //查询按钮操作
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    // 获取供应商审核列表
    async getList() {
      let params = { ...this.queryParams };
      this.loading = true;
      let data = await this.getreviewList(params);
      this.loading = false;
      if (data.code == 200) {
        this.reviewData = data.data.data;
        this.total = data.data.total;
      }
      this.setquery();
    },
    /**审核  */
    examine(row) {
      this.$router.push({
        path: "/promptlyAudit",
        query: {
          auditSerialNumber: row.serialNumber,
          businessId: row.businessId,
          isBusinessInfo: false, //true供应商详情，false审核详情
        },
      });
    },
    /**详情  */
    details(row) {
      this.$router.push({
        path: "/Regisreviewdetails",
        query: {
          auditSerialNumber: row.serialNumber,
          businessId: row.businessId,
          isBusinessInfo: false, //true供应商详情，false审核详情
        },
      });
    },
    /**记录  */
    record(row) {
      this.$router.push({
        path: "/approvedMemo",
        query: { id: row.id, businessId: row.businessId, type: "goods" },
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
